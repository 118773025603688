<template>
  <div class="discussion-board">
    <div class="container">
      <div class="info-section dashboard-panel">
        <div class="_section-head mb-0 pb-0">
          <h5>Recording</h5>
        </div>
        <div class="_section-body">
          <div class="content">
            <div class="row" v-if="isLoading == true">
              <CircularLoader />
            </div>
            <div class="player-container" v-else>
              <vue3-video-player :src="recording.file_url"></vue3-video-player>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircularLoader from "@/components/loaders/CircularLoader";
import CourseService from "@/services/CourseService.js";

export default {
  name: "Recordings",

  components: { CircularLoader },
  created() {
    this.getRecordingVideo();
  },
  data() {
    return {
      recording: [],
      recordingId: this.$route.params.id,
      isLoading: true,
    };
  },
  methods: {
    getRecordingVideo() {
      CourseService.getRecordingVideo({
        recording_id: this.recordingId,
      })
        .then((result) => {
          this.recording = result.data.recording;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/dashboard.scss";
</style>
